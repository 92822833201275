import {ref} from '@vue/composition-api';
import store from '@/store/index';

const usePublishingFundsPg = () => {
  // base data
  const publishingFundsPg = ref (null);
  const loading = ref (false);

  // methods
  const getPublishingFundsPg = async () => {
    loading.value = true;
    const {data, status} = await store.dispatch ('fetchPublishingFundsPg');
    loading.value = false;
    if (status != 200) return;
    publishingFundsPg.value = data;
    return data;
  };

  const updatePublishingFundsPg = async publishingFundsPg => {
    loading.value = true;
    const {data, status} = await store.dispatch ('updatePublishingFundsPg', publishingFundsPg);
    loading.value = false;
    if (status != 200 && status != 201) return;
    return data;
  };

  return {
    publishingFundsPg,
    loading,
    // methods
    getPublishingFundsPg,
    updatePublishingFundsPg,
  };
};

export default usePublishingFundsPg;
