<template>
  <FormTemplate>
    <template v-slot:form-body>
      <v-form ref="formManagedPublicPlace" @submit.prevent="saveForm">
        <v-row>
          <v-col cols="12" md="3">
            <label for="name">Nombre</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-text-field
              id="name"
              v-model="managedPublicPlace.name"
              outlined
              dense
              placeholder="Nombre"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <label for="description">Descripción</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-textarea
              id="description"
              v-model="managedPublicPlace.description"
              outlined
              dense
              placeholder="Descripción de la entidad"
              rows="4"
              :rules="required"
            ></v-textarea>
          </v-col>

          <v-col cols="12" md="3">
            <label for="btn_text">Texto del botón</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-text-field
              id="btn_text"
              v-model="managedPublicPlace.btn_text"
              outlined
              dense
              placeholder="Texto del botón"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <label for="btn_url">URL del botón</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-text-field
              id="btn_url"
              v-model="managedPublicPlace.btn_url"
              outlined
              dense
              placeholder="URL a redireccionar"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <label for="file_managed_public_places">Imagen</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-file-input
              id="file_managed_public_places"
              v-model="image"
              outlined
              prepend-icon=""
              dense
              placeholder="Elige una imagen"
              @change="onFileChange"
            ></v-file-input>
          </v-col>

          <v-col v-if="url || managedPublicPlaceEdited" cols="12" class="pb-0">
            <div class="icon-service center-sub-items">
              <img
                draggable="false"
                :src="url || loadImage(managedPublicPlace.img_managed_public_places)"
                :alt="managedPublicPlace.text"
              />
            </div>
          </v-col>

          <v-col offset-md="3" cols="9">
            <v-btn type="submit" :disabled="loading" :loading="loading" color="primary"> Guardar </v-btn>
            <v-btn type="reset" @click="reset" class="mx-2" outlined> Limpiar </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </FormTemplate>
</template>

<script>
import FormTemplate from '@/components/forms/FormTemplate.vue'
import { loadAPIImage } from '@/utils/images'

export default {
  components: {
    FormTemplate,
  },
  props: {
    managedPublicPlaceEdited: Object,
    loading: Boolean,
  },
  data() {
    return {
      required: [v => !!v || 'Requerido'],
      url: '',
      image: null,
      managedPublicPlace: this.managedPublicPlaceEdited ? { ...this.managedPublicPlaceEdited } : {},
    }
  },
  methods: {
    validateForm() {
      return this.$refs.formManagedPublicPlace.validate()
    },
    reset() {
      this.managedPublicPlace.icon = null
      this.managedPublicPlace.text = ''
      this.managedPublicPlace.urlText = ''
      this.managedPublicPlace.description = ''
      this.$refs.formManagedPublicPlace.resetValidation()
    },
    saveForm() {
      if (!this.validateForm()) return
      if (this.managedPublicPlaceEdited) {
        let DTO = { ...this.managedPublicPlace }

        if (this.image) {
          DTO = {
            ...DTO,
            file_managed_public_places: this.image,
          }
        }
        // // console.log('actualizar', DTO)
        this.$emit('beforeUpdate', DTO)
      } else {
        const DTO = { ...this.managedPublicPlace, file_managed_public_places: this.image }
        // // console.log('crear', DTO)
        this.$emit('beforeCreate', DTO)
      }
    },
    loadImage(src) {
      return loadAPIImage(src)
    },
    onFileChange(e) {
      if (!e) return
      this.url = URL.createObjectURL(e)
    },
  },
}
</script>

<style lang="scss" scoped>
.icon-service {
  padding: 10px;
}

.icon-service img {
  max-width: 200px;
  max-height: 200px;
}

.center-sub-items {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
