import { ref, computed } from "@vue/composition-api";
import store from "@/store/index"

const useManagedPublicPlaces = () => {

    // base data
    const dialog = ref(false);
    const managedPublicPlaceEdited = ref(null);
    const managedPublicPlaces = ref([]);
    const search = ref("");
    const loading = ref(false);

    // computed properties
    const titleDialog = computed(() =>
        managedPublicPlaceEdited.value ? "Editar Espacio Público" : "Agregar Espacio Público"
    );
    const subtitleDialog = computed(() =>
        managedPublicPlaceEdited.value ?
        "Rellene los campos correctamente para modificar los datos del Espacio Público" :
        "Rellene los campos correctamente para validar los datos del nuevo Espacio Público"
    );

    const closeDialog = () => {
        dialog.value = false;
        managedPublicPlaceEdited.value = null;
    }

    // methods
    const getManagedPublicPlacesList = async() => {
        const { data, status } = await store.dispatch("fetchManagedPublicPlaces")
        if (status != 200) return;
        managedPublicPlaces.value = data;
    }

    const createManagedPublicPlace = async(managedPublicPlace) => {
        loading.value = true;
        const { data, status } = await store.dispatch("createManagedPublicPlace", managedPublicPlace)
        loading.value = false;
        if (status != 200 && status != 201) return;
        await getManagedPublicPlacesList();
        closeDialog();
    }

    const updateManagedPublicPlace = async(managedPublicPlace) => {
        loading.value = true;
        const { data, status } = await store.dispatch("updateManagedPublicPlace", managedPublicPlace)
        loading.value = false;
        if (status != 200 && status != 201) return;
        await getManagedPublicPlacesList();
        closeDialog();
    }

    const removeManagedPublicPlace = async(managedPublicPlace) => {
        const val = await store.dispatch("generateConfirmDialog", {});
        if (!val) return;
        const { data, status } = await store.dispatch("removeManagedPublicPlace", managedPublicPlace)
        if (status != 200 && status != 201 && status != 204) return;
        await getManagedPublicPlacesList();
    }

    getManagedPublicPlacesList();

    return {
        dialog,
        managedPublicPlaceEdited,
        managedPublicPlaces,
        search,
        loading,
        // computed
        titleDialog,
        subtitleDialog,
        // methods
        changeSearch(ev) {
            search.value = ev
        },
        openDialog() {
            dialog.value = true;
        },
        openEdit(managedPublicPlace) {
            dialog.value = true;
            managedPublicPlaceEdited.value = {...managedPublicPlace };
        },
        openRemove: removeManagedPublicPlace,
        closeDialog,
        createManagedPublicPlace,
        updateManagedPublicPlace
    };
}

export default useManagedPublicPlaces;
