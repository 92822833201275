<template>
  <v-card>
    <PublishingFundsPgHeader></PublishingFundsPgHeader>
    <section class="form-full-width">
      <FormPublishingFundsPg :books="books" :loading="loading" :getPublishingFundsPg="getPublishingFundsPg" @beforeUpdate="updatePublishingFundsPg"></FormPublishingFundsPg>
    </section>
  </v-card>
</template>

<script>
import FormPublishingFundsPg from '@/components/forms/FormPublishingFundsPg.vue'
import PublishingFundsPgHeader from './PublishingFundsPgHeader.vue'
import usePublishingFundsPg from '@/composables/usePublishingFundsPg'
import useBooks from '@/composables/useBooks'

export default {
  components: {
    FormPublishingFundsPg,
    PublishingFundsPgHeader,
  },
  setup() {
    // Composition API
    const {
      publishingFundsPg,
      loading,
      // methods
      getPublishingFundsPg,
      updatePublishingFundsPg,
    } = usePublishingFundsPg()

    const { books } = useBooks();

    return {
      publishingFundsPg,
      books,
      loading,
      // methods
      getPublishingFundsPg,
      updatePublishingFundsPg,
    }
  },
}
</script>

<style lang="scss" scoped>
.form-full-width {
  padding: 10px;
}
</style>
