import { ref, computed } from "@vue/composition-api";
import store from "@/store/index"

const useBooks = () => {

  // base data
  const dialog = ref(false);
  const bookEdited = ref(null);
  const books = ref([]);
  const search = ref("");
  const loading = ref(false);

  // computed properties
  const titleDialog = computed(() =>
    bookEdited.value ? "Editar Libro" : "Agregar Libro"
  );
  const subtitleDialog = computed(() =>
    bookEdited.value ?
      "Rellene los campos correctamente para modificar los datos del Libro" :
      "Rellene los campos correctamente para validar los datos del nuevo Libro"
  );

  const closeDialog = () => {
    dialog.value = false;
    bookEdited.value = null;
  }

  // methods
  const getBooksList = async () => {
    const { data, status } = await store.dispatch("fetchBooks")
    if (status != 200) return;
    books.value = data;
  }

  const createBook = async (book) => {
    loading.value = true;
    const { data, status } = await store.dispatch("createBook", book)
    loading.value = false;
    if (status != 200 && status != 201) return;
    await getBooksList();
    closeDialog();
  }

  const updateBook = async (book) => {
    loading.value = true;
    const { data, status } = await store.dispatch("updateBook", book)
    loading.value = false;
    if (status != 200 && status != 201) return;
    await getBooksList();
    closeDialog();
  }

  const removeBook = async (book) => {
    const val = await store.dispatch("generateConfirmDialog", {});
    if (!val) return;
    const { data, status } = await store.dispatch("removeBook", book)
    if (status != 200 && status != 201 && status != 204) return;
    await getBooksList();
  }

  getBooksList();

  return {
    dialog,
    bookEdited,
    books,
    search,
    loading,
    // computed
    titleDialog,
    subtitleDialog,
    // methods
    changeSearch(ev) {
      search.value = ev
    },
    openDialog() {
      dialog.value = true;
    },
    openEdit(book) {
      dialog.value = true;
      bookEdited.value = {...book };
    },
    openRemove: removeBook,
    closeDialog,
    createBook,
    updateBook
  };
}

export default useBooks;
